import { Controller } from "@hotwired/stimulus";

export default class HiddenAreaController extends Controller<HTMLElement> {
  static targets = ["hiddenArea"];

  static values = {
    isHidden: {
      type: Boolean,
      default: false,
    },
  };

  hiddenAreaTargets!: HTMLElement[];

  isHiddenValue!: boolean;

  connect() {
    this.syncState();
  }

  isHiddenValueChanged() {
    this.syncState();
  }

  syncState() {
    this.hiddenAreaTargets.forEach(
      (item) => (item.hidden = this.isHiddenValue)
    );
  }

  changeToInverseValue(event: Event) {
    const { target } = event;
    if (!(target instanceof HTMLInputElement)) {
      return;
    }

    this.isHiddenValue = !target.checked;
  }
}
