// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>


// Example: Load Rails libraries in Vite.
//
import "@hotwired/turbo-rails";
import { Application } from '@hotwired/stimulus';
import { registerControllers } from "stimulus-vite-helpers";
import actionMorph from "../util/action-morph";
import "../custom_elements";

const controllers = import.meta.glob('../controllers/**/*_controller.ts', { eager: true });
const app = Application.start();

window.Stimulus = app;

registerControllers(app, controllers);

window.Turbo.StreamActions.morph = function() {
  actionMorph(this);
}
