import { Controller } from "@hotwired/stimulus";

export default class InvoiceEditController extends Controller {
  static targets = ["itemFieldset", "overallSum"];

  itemFieldsetTargets!: HTMLElement[];

  overallSumTarget!: HTMLElement;

  connect() {
    this.recomputeTotal();
  }

  recomputeSubtotal(event: InputEvent) {
    const target = event.currentTarget;
    if (!(target instanceof Element)) {
      return;
    }

    const fieldset = target.closest("fieldset");

    if (fieldset === null) {
      return;
    }

    const container = fieldset.querySelector(`[data-subtotal-container]`);

    if (!(container instanceof HTMLElement) || !fieldset) return;

    const total = this.totalFor(fieldset);
    if (total === null) {
      container.innerText = "-";
    } else {
      container.innerText = this.numberFormatter.format(total);
    }
    this.recomputeTotal();
  }

  get currentTotal() {
    return this.itemFieldsetTargets
      .map((target) => this.totalFor(target))
      .filter((a) => a !== null)
      .reduce((a, b) => a + b, 0);
  }

  get numberFormatter() {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  recomputeTotal() {
    this.overallSumTarget.innerText = this.numberFormatter.format(
      this.currentTotal
    );
  }

  totalFor(element: Element) {
    const targets = Array.from(element.querySelectorAll(`input[type="number"]`))
      .filter((f) => f instanceof HTMLInputElement)
      .filter((f) => !f.readOnly)
      .map((f) => Number.parseFloat(f.value));

    if (targets.some((a) => Number.isNaN(a))) {
      return null;
    }

    if (targets.length === 0) {
      return 0;
    }

    return targets.reduce((a, b) => a * b, 1);
  }
}
