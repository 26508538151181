type CommhexEmbedAttributes = NamedNodeMap & { randomKey: Attr };

const fetchUrl = (sgid: string) => `/embedded_files/${sgid}`;

function parseDoc(html: string = "") {
  return new DOMParser().parseFromString(html, "text/html");
}

export default class CommhexEmbeddedFile extends HTMLElement {
  static observedAttributes = ["randomKey"];

  constructor() {
    super();
    this._internals = this.attachInternals();
  }

  attributes!: CommhexEmbedAttributes;

  _internals: ElementInternals;

  connectedCallback() {
    this.refreshContents();
  }

  async refreshContents() {
    const randomKey = this.randomKeyValue;
    if (randomKey === null) return;
    const resp = await fetch(fetchUrl(randomKey), {
      headers: {
        "Content-Type": "text/vnd.commhex-embed.html",
      },
    });
    const text = await resp.text();
    if (this.randomKeyValue !== randomKey) return;
    const doc = parseDoc(text);
    this.replaceChildren(...doc.body.children);
  }

  attributeChangedCallback(name: string) {
    if (name === "randomKey") {
      void this.refreshContents();
    }
  }

  get randomKeyValue() {
    const attr = this.attributes.getNamedItem("random-key");
    if (attr === null) return null;
    return attr.value;
  }
}
