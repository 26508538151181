import { Controller } from "@hotwired/stimulus";

export default class ZonedTimeInputController extends Controller<HTMLElement> {
  static targets = ["localTimeInput", "timeZoneInput", "dataListItem"];

  localTimeInputTarget!: HTMLInputElement;
  timeZoneInputTarget!: HTMLInputElement;
  dataListItemTargets!: HTMLOptionElement[];

  connect() {
    this.syncLocalTimezone();
  }

  syncLocalTimezone() {
    const { value: zoneValue } = this.timeZoneInputTarget;
    const {
      dataset: { isoTimestamp },
    } = this.localTimeInputTarget;
    if (zoneValue === "UTC" && isoTimestamp) {
      return this.convertToLocalTimezone(isoTimestamp);
    } else if (zoneValue === "") {
      this.setTimezoneValueToLocalTime();
    }
  }

  setTimezoneValueToLocalTime() {
    const { localTimeZone } = this;

    if (localTimeZone === undefined) {
      console.error("Could not deterine local time zone");
      return false;
    }

    this.timeZoneInputTarget.value = localTimeZone.value;

    return true;
  }

  convertToLocalTimezone(isoTimestamp: string) {
    const d = new Date(isoTimestamp);
    // invalid time value
    if (isNaN(d.getTime())) {
      console.error("Bad ISO timestamp:", isoTimestamp);
    }
    const setLocal = this.setTimezoneValueToLocalTime();

    if (!setLocal) {
      return;
    }

    this.localTimeInputTarget.value = d.toLocaleString("sv");
  }

  get localTimeZone() {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    return this.findTimeZoneFor(timeZone);
  }

  findTimeZoneFor(timeZone: string) {
    return this.dataListItemTargets.find((element) => {
      return (
        element.dataset.railsName === timeZone ||
        element.dataset.tzinfoName === timeZone
      );
    });
  }
}
