import { Controller } from "@hotwired/stimulus";

export default class FormbuilderQuestionController extends Controller<HTMLElement> {
  static values = {
    ordinal: Number,
    formQuestionId: Number,
  };

  ordinalValue!: number;
  formQuestionIdValue!: number;

  openMoveDialog() {
    this.dispatch("requestOrdinalMove", {
      detail: {
        ordinal: this.ordinalValue,
        formQuestionId: this.formQuestionIdValue,
      },
    });
  }
}
