import { Controller } from "@hotwired/stimulus";
import { type UploadInformation } from "../embed/upload_controller";

export default class FormResponseReferenceFileUploadController extends Controller<HTMLElement> {
  static targets = [
    "dialog",
    "progress",
    "messagebox",
    "filepicker",
    "updateButton",
  ];

  static values = {
    randomkey: String,
  };

  dialogTarget!: HTMLDialogElement;
  progressTarget!: HTMLProgressElement;

  messageboxTarget!: HTMLElement;

  filepickerTarget!: HTMLInputElement;

  updateButtonTarget!: HTMLButtonElement;

  randomkeyValue!: string;

  onFileSelected(event: InputEvent) {
    const { target } = event;

    if (!(target instanceof HTMLInputElement)) {
      throw new Error("target must be an input element");
    }
    const { files } = target;

    if (files === null || files.length < 1) {
      console.log("No files selected");
      return;
    }

    const file = files.item(0);

    if (file === null) {
      return;
    }

    this.startFileUpload(file);
  }

  clickUpload() {
    console.log("clickUpload");
    this.filepickerTarget.click();
  }

  connect() {
    const {
      dialogTarget,
      progressTarget,
      messageboxTarget,
      filepickerTarget,
      randomkeyValue,
    } = this;

    console.log({
      controller: this,
      dialogTarget,
      progressTarget,
      messageboxTarget,
      filepickerTarget,
      randomkeyValue,
    });
  }

  async startFileUpload(file: File) {
    this.dialogTarget.showModal();
    const fileChecksum = await this.hashFile(file);
    this.resetProgress();

    const uploadInfo = await this.createReferenceFile(file, fileChecksum);

    await this.uploadToStorage(file, uploadInfo);

    this.dialogTarget.close();

    this.updateButtonTarget.click();
  }

  async uploadToStorage(file: File, uploadInfo: UploadInformation) {
    const { default: UploadController } = await import(
      "../embed/upload_controller"
    );

    const controller = new UploadController(file, uploadInfo);

    controller.addEventListener("progress", this.onProgress);

    await controller.finished;

    controller.removeEventListener("progress", this.onProgress);
  }

  async createReferenceFile(file: File, checksum: string) {
    this.displayMessage(`Registering file ${file.name} as a reference file...`);
    const body = {
      form_response_reference_file_create: {
        file_checksum: checksum,
        filename: file.name,
        file_size: file.size,
        file_content_type: file.type,
      },
    };

    const resp = await fetch(
      `/form_responses/${this.randomkeyValue}/reference_files`,
      {
        headers: {
          "X-CSRF-Token": this.getCSRF(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        method: "POST",
      }
    );

    const json = await resp.json();
    const blobObj = typeof json === "object" ? json.blob || {} : {};
    const directUploadUrl =
      typeof blobObj.direct_upload_url === "string"
        ? (blobObj.direct_upload_url as string)
        : "";
    const headers = (blobObj.headers ?? {}) as Record<string, string>;

    return { directUploadUrl, headers };
  }
  async hashFile(file: File) {
    this.displayMessage(`Uploading file ${file.name}: pre-processing...`);
    const { default: FileHasher } = await import("../embed/file_hasher");
    const hasher = new FileHasher(file);
    hasher.addEventListener("progress", this.onProgress);
    const fileChecksum = await hasher.finished;
    hasher.removeEventListener("progresss", this.onProgress);

    return fileChecksum;
  }

  getCSRF() {
    const found = Array.from(
      document.querySelectorAll(`meta[name="csrf-token"]`)
    ).find((elem) => elem instanceof HTMLMetaElement);

    return found?.content ?? "";
  }

  displayMessage(message: string) {
    this.messageboxTarget.innerText = message;
  }

  resetProgress() {
    this.progressTarget.value = 0;
  }

  onProgress = (event: Event) => {
    if (!(event instanceof ProgressEvent)) {
      return;
    }

    this.progressTarget.max = event.total;
    this.progressTarget.value = event.loaded;
  };
}
