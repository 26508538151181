import { Idiomorph, type Callbacks } from "idiomorph/dist/idiomorph.esm";
import { StreamElement } from "@hotwired/turbo";

function targets(element: StreamElement) {
  if (element.target) {
    const targeted = element.ownerDocument?.getElementById(element.target);
    if (targeted) {
      return [targeted];
    } else {
      return [];
    }
  } else if (element.targets) {
    const elements = element.ownerDocument?.querySelectorAll(element.targets);
    return Array.from(elements || []);
  }
  return [];
}

function templateChild(element: StreamElement) {
  const { firstElementChild } = element;

  if (firstElementChild === null) {
    const template = element.ownerDocument.createElement("template");
    element.appendChild(template);
    return template;
  } else if (firstElementChild instanceof HTMLTemplateElement) {
    return firstElementChild;
  } else {
    throw new Error("No template applicable");
  }
}

function templateContent(element: StreamElement) {
  return templateChild(element).content.cloneNode(true);
}

function beforeNodeAdded(node: Node): boolean {
  return !(
    node instanceof Element &&
    node.id &&
    node.hasAttribute("data-turbo-permanent") &&
    document.getElementById(node.id)
  );
}

function dispatch(
  eventName: string,
  {
    target,
    cancelable,
    detail,
  }: { target?: HTMLElement; cancelable?: boolean; detail?: any }
) {
  const event = new CustomEvent(eventName, {
    cancelable,
    bubbles: true,
    composed: true,
    detail,
  });
  if (target && target.isConnected) {
    target.dispatchEvent(event);
  } else {
    document.documentElement.dispatchEvent(event);
  }
  return event;
}

const callbacks: Callbacks = {
  beforeNodeAdded,
  beforeNodeRemoved: beforeNodeAdded,
  beforeNodeMorphed(target, newElement) {
    if (target instanceof HTMLElement) {
      if (!target.hasAttribute("data-turbo-permanent")) {
        const event = dispatch("turbo:before-morph-element", {
          cancelable: true,
          target,
          detail: { newElement },
        });
        return !event.defaultPrevented;
      }
      return false;
    }

    return true;
  },
  beforeAttributeUpdated(attributeName, target, mutationType) {
    const event = dispatch("turbo:before-morph-attribute", {
      cancelable: true,
      target: target instanceof HTMLElement ? target : undefined,
      detail: {
        attributeName,
        mutationType,
      },
    });
    return !event.defaultPrevented;
  },
  afterNodeMorphed(target, newElement) {
    if (newElement instanceof HTMLElement) {
      dispatch("turbo:morph-element", {
        target: target instanceof HTMLElement ? target : undefined,
        detail: { newElement },
      });
    }
  },
};

export function performMorph(
  original: Node,
  newNode: Node,
  morphStyle: "innerHTML" | "outerHTML" = "outerHTML"
) {
  Idiomorph.morph(original, newNode, {
    morphStyle,
    callbacks,
  });
}

export default function morph(element: StreamElement) {
  const morphStyle = element.hasAttribute("children-only")
    ? "innerHTML"
    : "outerHTML";
  targets(element).forEach((toMorph) => {
    performMorph(toMorph, templateContent(element), morphStyle);
  });
}
