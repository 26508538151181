import { Controller } from "@hotwired/stimulus";

export default class TimeAgoController extends Controller<HTMLTimeElement> {
  static values = {
    isoTimestamp: String,
    words: String,
  };

  isoTimestampValue: string | undefined;
  wordsValue: string | undefined;

  currentDisplay: "words" | "timestamp" = "words";

  get datetime(): Date | undefined {
    const { isoTimestampValue } = this;

    if (isoTimestampValue === undefined || isoTimestampValue === "")
      return undefined;
    return new Date(isoTimestampValue);
  }

  get formattedDatetime(): string | undefined {
    const { datetime } = this;

    if (datetime === undefined) {
      return undefined;
    }

    const format = new Intl.DateTimeFormat(undefined, {
      dateStyle: "long",
      timeStyle: "long",
    });
    return format.format(datetime);
  }

  swapContents() {
    if (this.currentDisplay === "words") {
      this.currentDisplay = "timestamp";
      this.element.textContent = this.formattedDatetime || "INVALID DATE";
    } else {
      this.currentDisplay = "words";
      this.element.textContent = this.wordsValue || "??";
    }
  }
}
