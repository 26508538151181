import { Controller } from "@hotwired/stimulus";

const SUBMIT_TIME = 500;

export default class AutosubmitController extends Controller<HTMLFormElement> {
  ongoingTimeout: number | undefined = undefined;

  connect(): void {
    super.connect();
    if (!(this.element instanceof HTMLFormElement)) {
      // Should not happen tbh
      console.error(
        "You passed a bad (not-form) element to AutosubmitController, why ;-;",
        { element: this.element }
      );
    }
  }

  /**
   * Submit the form async with JS, to preserve changes when you may be mutating
   * a form in multiple ways at a time.
   */
  async asyncSubmit() {
    const formdata = new FormData(this.element);

    return await fetch(this.element.action, {
      method: this.element.method,
      body: formdata,
    });
  }

  async asyncSubmitAndRenderTurbo() {
    const resp = await this.asyncSubmit();

    if (
      (resp.headers.get("content-type") || "").startsWith(
        "text/vnd.turbo-stream.html"
      )
    ) {
      Turbo.session.renderStreamMessage(await resp.text());
    }

    return resp;
  }

  startBackgroundSubmit() {
    this.clearOngoingTimeouts();
    this.ongoingTimeout = window.setTimeout(() => this.submit(), SUBMIT_TIME);
  }

  stopBackgroundSubmit() {
    this.clearOngoingTimeouts();
  }

  submit() {
    this.element.requestSubmit();
  }

  clearOngoingTimeouts() {
    if (this.ongoingTimeout) {
      window.clearTimeout(this.ongoingTimeout);
    }

    this.ongoingTimeout = undefined;
  }
}
