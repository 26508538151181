import { Controller } from "@hotwired/stimulus";

export default class FilePreviewController extends Controller {
  static targets = ["preview"];

  previewTarget!: HTMLElement;
  hasPreviewTarget!: boolean;

  connect() {}

  changeFile(event: Event) {
    const { target } = event;
    if (target && target instanceof HTMLInputElement) {
      const { files } = target;

      if (files && files[0]) {
        void this.previewFile(files[0]);
      }
    }
  }
  async previewFile(file: File) {
    const { previewImageElement } = this;

    if (previewImageElement === null) return null;

    const event = await new Promise<ProgressEvent<FileReader>>(
      (resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = resolve;
        fileReader.onerror = reject;
        fileReader.readAsDataURL(file);
      }
    );

    const result = event.target?.result;

    if (typeof result === "string") {
      previewImageElement.src = result;
    }
  }

  get previewImageElement(): HTMLImageElement | null {
    if (this.previewTarget instanceof HTMLImageElement) {
      return this.previewTarget;
    }

    return null;
  }
}
