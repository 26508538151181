import { Controller } from "@hotwired/stimulus";

type ColorMode = "dark" | "light";

function invertMode(mode: ColorMode): ColorMode {
  return mode === "dark" ? "light" : "dark";
}

function castColorMode(existing: string | undefined | null): ColorMode {
  if (existing === undefined || existing === null || existing == "light") {
    return "light";
  } else {
    return "dark";
  }
}

export const STORAGE_KEY = "COMMFORMS_COLOR_MODE";

export default class DarkModeButtonController extends Controller {
  connect() {
    this.setMode(castColorMode(window.localStorage.getItem(STORAGE_KEY)));
  }

  toggle() {
    const mode = this.modeFromElement();
    const newMode = invertMode(mode);
    this.setMode(newMode);
  }

  setMode(colorMode: "dark" | "light") {
    const element = document.documentElement;
    element.dataset.colorMode = colorMode;
    window.localStorage.setItem(STORAGE_KEY, colorMode);
  }

  modeFromElement(): "dark" | "light" {
    const element = document.documentElement;
    return castColorMode(element.dataset.colorMode);
  }
}
