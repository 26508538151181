import { Controller } from "@hotwired/stimulus";
import { performMorph } from "../util/action-morph";

interface BeforeFrameRenderEvent extends Event {
  detail: {
    render: (currentElement: Element, newElement: Element) => void;
  };
}

export default class MorphFrameController extends Controller<HTMLElement> {
  morphRender(event: BeforeFrameRenderEvent) {
    event.detail.render = (currentElm, newElm) => {
      performMorph(currentElm, newElm, "outerHTML");
    };
  }
}
