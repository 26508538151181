import { Controller } from "@hotwired/stimulus";

function isInput(input: Element): input is HTMLInputElement {
  return input instanceof HTMLInputElement;
}

export default class SumNumbersController extends Controller {
  static targets = ["summand", "sum"];

  static values = {
    currencyCode: { type: String, default: "usd" },
  };

  currencyCodeValue!: string;

  summandTargets!: HTMLElement[];
  hasSumTarget!: boolean;
  sumTarget!: HTMLElement;

  connect() {
    this.compute();
  }

  compute() {
    if (!this.hasSumTarget) {
      return;
    }
    const itl = new Intl.NumberFormat("en", {
      style: "currency",
      currency: this.currencyCodeValue.toUpperCase(),
    });
    this.sumTarget.innerHTML = itl.format(this.sum);
  }

  get sum() {
    return this.summandTargets.reduce((acc: number, elem: HTMLElement) => {
      let current = 0;

      if (isInput(elem)) {
        current = parseFloat(elem.value);
      }

      if (elem.dataset.numberValue) {
        current = parseFloat(elem.dataset.numberValue);
      }

      if (Number.isNaN(current)) {
        current = 0;
      }

      return acc + current;
    }, 0);
  }
}
