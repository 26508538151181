import { Controller } from "@hotwired/stimulus";
import { FormbuilderRequestOrdinalMoveEvent } from "../events/formbuilder_request_ordinal_move_event";

export default class OrdinalMoveDialogController extends Controller<HTMLDialogElement> {
  static targets = ["formQuestionIdInput", "ordinalInput"];

  formQuestionIdInputTarget!: HTMLInputElement;
  hasFormQuestionIdInputTarget!: boolean;
  ordinalInputTarget!: HTMLInputElement;
  hasOrdinalInputTarget!: boolean;

  closeDialog() {
    this.element.close();
  }

  ordinalMoveRequested(event: FormbuilderRequestOrdinalMoveEvent) {
    const { ordinal, formQuestionId } = event.detail;

    if (this.hasFormQuestionIdInputTarget) {
      this.formQuestionIdInputTarget.value = String(formQuestionId);
    } else {
      console.error("No form question id target");
    }

    if (this.hasOrdinalInputTarget) {
      this.ordinalInputTarget.value = String(ordinal);
    }

    this.element.showModal();
  }
}
