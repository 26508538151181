import { Controller } from "@hotwired/stimulus";

export default class FileEmbedderController extends Controller {
  static targets = ["progressContainer"];
  readonly progressContainerTarget!: Element;
  readonly hasProgressContainerTarget!: boolean;

  static values = {
    embedSgid: String,
    embedTarget: String,
  };

  readonly embedSgidValue!: string;
  readonly embedTargetValue!: string;

  filesSelectedOnField(event: InputEvent) {
    const { target } = event;
    if (!(target instanceof HTMLInputElement)) {
      return console.error("Target is not correct", event);
    }
    const files = Array.from(target.files || []);
    this.uploadFiles(files);
    target.files = null;
  }

  async uploadFiles(files: File[]) {
    return await Promise.all(
      files.map(async (file) => {
        const description = window.prompt(`Add description for ${file.name}`);
        if (description === null) return Promise.resolve(null);
        return this.uploadFile(file, description);
      })
    );
  }

  async uploadFile(file: File, description: string) {
    const { embedSgidValue, embedTargetValue } = this;
    if (embedSgidValue === "" || embedTargetValue === "") {
      return console.error("Not enough values to actually perform an embed", {
        embedSgidValue,
        embedTargetValue,
      });
    }
    const [{ default: EmbedFile }] = await Promise.all([
      import("../embed/embed_file"),
    ]);

    const embed = new EmbedFile(
      file,
      description,
      embedSgidValue,
      embedTargetValue
    );
    this.dispatch("file-embed-upload-start", {
      detail: { embed },
      bubbles: true,
      cancelable: false,
    });
    const randomKey = await embed.finished;
    this.dispatch("file-embed-uploaded", {
      detail: { randomKey, filename: file.name, embed, file },
    });
  }
}
