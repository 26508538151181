import { Controller } from "@hotwired/stimulus";
export default class DialogController extends Controller<HTMLDialogElement> {
  connect() {
    console.log(this);
  }
  requestClose() {
    console.log("Requesting a close");
    this.element.close();
    if ("hidePopover" in this.element) {
      const elm = this.element as unknown as { hidePopover: unknown };
      if (typeof elm.hidePopover === "function") {
        elm.hidePopover();
      }
    }
  }
}
