import { Controller } from "@hotwired/stimulus";

export default class DuplicateController extends Controller<HTMLElement> {
  duplicateAfter() {
    this.element.after(this.cloneElement());
  }

  duplicateBefore() {
    this.element.before(this.cloneElement());
  }

  cloneElement() {
    const node = this.element.cloneNode(true);

    if (node instanceof HTMLElement) {
      node.querySelectorAll("input").forEach((input) => (input.value = ""));
    }

    return node;
  }
}
