import { Controller } from "@hotwired/stimulus";
import nextFrame from "../util/next_frame";

export default class CopyableController extends Controller<HTMLElement> {
  static targets = ["content"];

  contentTarget!: HTMLInputElement;

  successTarget!: HTMLElement;

  hasSuccessTarget!: boolean;

  copy() {
    navigator.clipboard.writeText(this.contentTarget.value);

    void this.indicateSuccess();
  }

  async indicateSuccess() {
    if (!this.hasSuccessTarget) return;

    this.successTarget.classList.add("success");

    await nextFrame();

    this.successTarget.classList.remove("success");
  }
}
