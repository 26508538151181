const STYLE = `
  input {
    color: inherit;
    background-color: inherit;
    width: 100%;
    height: 100%;
    font-size: inherit;
    outline: none;
    border: none;
  }
`;

function parseValidDate(dateStr: string | null | undefined) {
  if (dateStr === null || dateStr === undefined) return null;

  const date = new Date(dateStr);

  if (Number.isNaN(date.getTime())) return null;

  return date;
}

// Datetime inputs do not want a time zone, so we do some magic stuff here, yay, I guess.
function formatForDatetime(date: Date | null) {
  if (date === null) return "";

  const str = date.toLocaleString("sv");
  return str;
}
export default class CommhexDatePicker extends HTMLElement {
  static formAssociated = true;
  static observedAttributes = ["value", "name", "required"];

  internals_ = this.attachInternals();

  #dateValue: Date | null = null;

  #inputElement: HTMLInputElement | undefined = undefined;

  constructor() {
    super();
  }

  attributeChangedCallback(name: string, _oldValue: string, newValue: string) {
    if (name === "value") {
      this.#setValueFromString(newValue);
      this.#syncInputToValue();
    } else if (name === "name") {
      this.#setFormValue();
    }
  }

  // We only sync the input element to our internal value sometimes - basically when
  // somebody changes our `value` prop.
  #syncInputToValue() {
    const input = this.#inputElement;

    if (input === undefined) return undefined;

    input.value = formatForDatetime(this.#dateValue);
  }

  connectedCallback() {
    const shadow = this.attachShadow({ mode: "open" });
    const style = document.createElement("style");
    style.innerText = STYLE;
    shadow.appendChild(style);

    this.#inputElement = this.#buildInput();

    shadow.appendChild(this.#inputElement);
  }

  disconnectedCallback() {
    let elem = this.#inputElement;

    if (elem) {
      elem.removeEventListener("input", this.onInputChanged);
    }
  }

  onInputChanged = (event: Event) => {
    const { target } = event;
    if (target instanceof HTMLInputElement) {
      this.#setValueFromString(target.value);
    } else {
      console.error("Bad event", event);
    }
  };

  checkValidity() {
    return this.internals_.checkValidity();
  }

  updateValidity(newValue: string) {
    if (this.isRequired && newValue.length === 0) {
      this.internals_.setValidity({ valueMissing: true });
    }
  }

  reportValidity() {
    return this.internals_.reportValidity();
  }

  #buildInput() {
    const input = document.createElement("input");
    input.type = "datetime-local";
    input.value = formatForDatetime(this.dateValue);
    input.disabled = this.getAttribute("disabled") !== null;
    input.addEventListener("input", this.onInputChanged);

    return input;
  }

  #setValueFromString(value: string) {
    const date = parseValidDate(value);
    console.log({ element: this, date });
    this.#dateValue = date;
    this.#setFormValue();
  }

  #setFormValue() {
    this.internals_.setFormValue(this.#dateValue?.toISOString() ?? null);
  }

  set value(newValue: string) {
    this.setAttribute("value", newValue);
  }

  get dateValue() {
    return parseValidDate(this.getAttribute("value"));
  }

  get isRequired() {
    return this.getAttribute("required") !== null;
  }
}
